/**
 * Arquivo responsavel por controlar pixels configurdos pelos usuarios da builderall no funil.
 */

import Product from './produto';

let pixelEnabled = {
    googleTagManager: false,
    googleAnalytics: false,
    facebookPixel: false,
    insertDirectDom: false
}

/**
 * Data para atribuir ao facebook com a nova API
 * 
 * @type {{id: null, access_token: null, funnelsDomain: null}}
 */
let fbData = {
    id: null,
    accessToken: null,
    funnelsDomain:  null,
    ur: null,
    subdomain: null
}

/**
 * 
 * @param IdPixel
 * @constructor
 */
function googleTagManager(IdPixel){
    let noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${IdPixel}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    let script = document.createElement('script');
    script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${IdPixel}');
    `;

    document.body.appendChild(noscript);
    document.head.appendChild(script);

    pixelEnabled.googleTagManager = true;
}

/**
 * 
 * @param IdPixel
 * @constructor
 */
function googleAnalytics(IdPixel){    
    let script = document.createElement('script');
    script.innerHTML = `
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    
        ga('create', '${IdPixel}', 'auto');
        ga('send', 'pageview');
    `;

    document.head.appendChild(script);
    
    pixelEnabled.googleAnalytics = true;
}

function googleAnalytics4(IdPixel){
    let script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${IdPixel}`;

    document.head.appendChild(script);

    setTimeout(() => {
        gtag('js', new Date());

        gtag('config', IdPixel);

        gtag('event', 'initiate_checkout', {
            hitType: 'event',
            location: location.href,
            page: location.pathname,
            eventCategory: 'checkout',
            eventAction: 'pageload'
        });
    }, 1000);


    pixelEnabled.googleAnalytics = true;
}

/**
 * 
 * @param IdPixel
 * @constructor
 */
function facebookPixel(IdPixel){
    let noscript = document.createElement('noscript');
    noscript.innerHTML = `<img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=${IdPixel}&ev=PageView&noscript=1"/>`;
    
    let script = document.createElement('script');
    script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${IdPixel}');
    `;

    document.head.appendChild(script);
    document.head.appendChild(noscript);
    
    pixelEnabled.facebookPixel = true;
}

/**
 * 
 * @param pixel
 */
function insertDirectDom(pixelHtml) {
    if (pixelHtml.search('facebook') !== -1) {
        pixelHtml = pixelHtml.replace(/[\r\n]/gm, '');

        let regex = new RegExp(/<script>(.*?)<\/script><noscript>(.*?)<\/noscript>/gm);
        let resultRegexHtml = regex.exec(pixelHtml);

        let script = document.createElement('script');
        script.innerHTML = resultRegexHtml[1];
        
        let noscript = document.createElement('noscript');
        noscript.innerHTML = resultRegexHtml[2];
        
        document.head.appendChild(script);
        document.head.appendChild(noscript);

        fbq('track', 'InitiateCheckout', {
            value: parseFloat(Product.getProduto().valor).toFixed(2),
            currency: Product.getProduto().currency
        });
        
        pixelEnabled.insertDirectDom = true;
        pixelEnabled.facebookPixel = true;
    }

}

/**
 * 
 * @param Platform - fb (Facebook) | gmt (Google Tag Manager) | ga (Google Analytics)
 * @param NameEvent - Nome do evento a ser disparado
 * @param Config - Configurações do evento
 */
function createEventPixel(Platform, NameEvent, Config, EventID, retries = 0){
    var maxRetry = 300; // tentar por 30 segundos
    let eventId = EventID || null;

    try{
        if(Platform == 'fb' && typeof(fbq) == 'function'){
            console.log(Config);
            
            fbq('track', NameEvent, Config, {eventID: eventId});

            return;
        }

        if(Platform == 'ga' && typeof(gtag) == 'function'){
            console.log(Config);
            
            gtag('event', NameEvent, Config);

            return;
        }

        if (['fb', 'ga'].includes(Platform) && retries < maxRetry) {
            console.log(`Tentativas retry ${Platform} createEventPixel() -> ${retries}`);

            retries++;

            setTimeout(() => createEventPixel(Platform, NameEvent, Config, eventId, retries), 100);
        }

    }catch(error){
        console.log(error);
    }
}

function addDataLayer(Event, Data){
    let newData = Object.assign({'event': Event}, Data);
    
    dataLayer.push(newData);
}

function setFbData(id, accessToken, funnelsDomain, ur, subdomain){
    fbData.id = id;
    fbData.accessToken = accessToken;
    fbData.funnelsDomain = funnelsDomain;
    fbData.ur = ur;
    fbData.subdomain = subdomain;

    pixelEnabled.facebookPixel = true;
}

export default {
    googleAnalytics,
    googleAnalytics4,
    googleTagManager,
    facebookPixel,
    insertDirectDom,
    pixelEnabled,
    createEventPixel,
    addDataLayer,
    fbData,
    setFbData
}