<template>
    <div class="paymentConfirmation">

        <div class="paymentConfirmation_header">
            <div class="paymentConfirmation_header_title">
                <h2 v-if="paymentMethodSelected == 'boleto'"><i class="icon icon-check-circle"></i> {{ translateWord('Boleto Gerado Com Sucesso!') }}</h2>
                <h2 v-else><i class="icon icon-check-circle"></i> {{ translateWord('Pagamento efetuado com sucesso!') }}</h2>
            </div>

            <div class="paymentConfirmation_header_desc">
                <p v-if="paymentMethodSelected == 'boleto'">{{ translateWord('Parabéns por escolher a Builderall, a plataforma de marketing digital mais completa do planeta, como a plataforma que você usará para construir e expandir seus negócios. Obrigado por nos escolher para auxiliá-lo na construção dos seus sonhos!') }}</p>
                <p v-else>{{ translateWord('Parabéns por escolher a Builderall, a plataforma de marketing digital mais completa do planeta, para ser sua plataforma que irá construir e expandir seus negócios. Agradecemos a sua escolha para a realização dos seus sonhos!') }}</p>
            </div>
        </div>

        <div class="paymentConfirmation_content">
            <div v-if="paymentMethodSelected == 'boleto'" class="paymentConfirmation_content_boleto">
                <div class="paymentConfirmation_content_boleto_barCode">
                    <img class="paymentConfirmation_content_boleto_barCode_img" :src="boleto.barCodeImage" :title="translateWord('Codigo de barra')" :alt="translateWord('Codigo de barra')">
                    <p class="paymentConfirmation_content_boleto_barCode_namber">{{ boleto.barCode }}</p>
                </div>


                <div class="paymentConfirmation_content_boleto_actions">
                    <p @click="copyBarcode(boleto.barCode)" class="paymentConfirmation_content_boleto_actions_action boletoCopyBarCode">{{ translateWord('Copiar código de Barras') }}</p>
                    <p @click="openViewBoleto" class="paymentConfirmation_content_boleto_actions_action boletoView">{{ translateWord('Visualizar Boleto') }}</p>

                    <p v-if="!clickRedir" @click="goRedir" class="paymentConfirmation_content_boleto_actions_action boletoAction">{{ translateWord('VOLTAR AO INICIO') }}</p>
                    <img v-else class="loadingImage paymentConfirmation_content_normal_iconLoading" src="../assets/imagem/loading-2.svg" alt="">
                </div>
            </div>

            <div v-else class="paymentConfirmation_content_normal">
                <p class="paymentConfirmation_content_normal_text">{{ translateWord('Você será redirecionado em') }}</p>

                <span v-if="timeRedirect != 0" class="paymentConfirmation_content_normal_time"><span>{{ timeRedirect }}</span></span>
                <img v-else class="loadingImage paymentConfirmation_content_normal_iconLoading" src="../assets/imagem/loading-2.svg" alt="">
            </div>
        </div>

        <!--
        <div class="row flex-center">
            <div class="col-lg-14 col-md-24">
                <Box padding-big v-if="paymentMethodSelected != 'boleto'">
                    <template v-if="product.isTrialDays">
                        <h2 class="paymentConfirmation_title"><i class="icon icon-check-circle"></i> {{ translateWord('Pagamento Autorizado') }}</h2>
                        
                        <p  class="paymentConfirmation_desc" v-html="translateWord('Seu pagamento para o plano <\b>{0}<\/b>, foi autorizado com sucesso.', [product.title])"></p>
                    </template>
                    
                    <template v-else>
                        <h2 class="paymentConfirmation_title"><i class="icon icon-check-circle"></i> {{ translateWord('Pagamento Confirmado') }}</h2>
                        <p  class="paymentConfirmation_desc" v-html="translateWord('Seu pagamento do <\b>{0}<\/b>, foi realizado com sucesso.', [product.title])"></p>
                    </template>
                    
                    <div class="paymentConfirmation_actions">
                        <p><b>{{ translateWord('Você sera redirecionado para pagina de destino em:') }}</b></p>
                        <p class="paymentConfirmation_actions_timeRedirect">
                            {{ timeRedirect }}
                        </p>
                    </div>
                </Box>

                <Box padding-big v-if="paymentMethodSelected == 'boleto'">
                    <h2 class="paymentConfirmation_title"><i class="icon icon-check-circle"></i> Boleto gerado com sucesso</h2>
                    <p  class="paymentConfirmation_desc">Apos a identificação do pagamento. O {{product.title}} sera liberado</p>

                    <div class="paymentConfirmation_boletoDetails">
                        <div class="paymentConfirmation_boletoDetails_barcode">
                            <img :src="boleto.barCodeImage" alt="Diners Club" title="Diners Club">
                            <span>{{ boleto.barCode }}</span>

                            <p @click="openViewBoleto" class="paymentConfirmation_boletoDetails_barcode_view"><i class="icon icon-eye"></i> VISUALIZAR BOLETO</p>
                        </div>

                        &lt;!&ndash;<div class="paymentConfirmation_boletoDetails_qrcode">
                            <img :src="boleto.qrCode" alt="QRCode boleto Builderall" title="QRCode boleto Builderall">
                        </div>&ndash;&gt;
                    </div>

                    <div class="paymentConfirmation_actions">

                        <at-button class="paymentConfirmation_actions_goDash" @click.prevent="goRedir" type="primary" size="large">
                            {{ messageButton }}
                        </at-button>
                    </div>
                </Box>
            </div>
        </div>

        -->
    </div>
</template>

<script>    
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import cookie from 'js-cookie';
import axios from 'axios';

import Helper from '../assets/js/helper';
import PixelAnalytics from '../assets/js/PixelAnalytics';


import Box from '../components/Box';

export default {
    name: "PaymentConfirmation",
    data(){
        return {
            redirectUrl: null,
            timeRedirect: 5,
            clickRedir: false
        }
    },
    computed: {
        ...mapState([
            'user',
            'userFather',
            'paymentMethodSelected',
            'boleto',
            'endpointOffice',
            'fatura',
            'isPaymentDomain',
            'isPaymentStorage',
            'isPaymentDomainExtraConnection',
            'bgResponse'
        ]),
        ...mapGetters([
            'product',
            'translateWord',
            'isSponsorBuilderall',
            'getSlug'
        ]),
        messageButton(){
            return this.$route.query['url-after-payment'] ? this.translateWord('Voltar') : this.translateWord('Ir para o Dashboard')
        }
    },
    async created(){
        window.scrollTo(0,0);

        this.enabledFullScreenLoading({ status: false });
        
        await this.verifyContactSupport();
        await this.verifyPaymentIsBoleto();

        this.verifySalesParam();

        this['impact/sale']();

        setTimeout(async () => {
            this.clearDataNote() 
        }, 300);
        
        await this.verifyPixelFinishBuy();

        this.createUrlRedirect();

        Helper.setPixelBuilderallFinishPayment(this.user.user_identifier, this.redirectUrl);

        PixelAnalytics.addDataLayer('StepPaymentConfirmed', {
            name: this.product.title,
            currency: this.product.currency,
            amount: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
        });

        PixelAnalytics.addDataLayer('successfullPayment', {
            name: this.product.title,
            currency: this.product.currency,
            amount: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
        });

        PixelAnalytics.addDataLayer('ThirdStep');
        PixelAnalytics.addDataLayer('Purchase');

        await this.verifyPixelPurchase();

        if(PixelAnalytics.pixelEnabled.facebookPixel){
            PixelAnalytics.createEventPixel('fb', 'ThirdStep');
        }

        if(PixelAnalytics.pixelEnabled.googleAnalytics){
            PixelAnalytics.createEventPixel('ga', null, {
                hitType: 'event',
                location: location.href,
                page: location.pathname,
                eventCategory: 'ThirdStep'
            });
        }

        if(this.paymentMethodSelected != 'boleto') this.startTimeRedirectUser();
    },
    methods: {
        ...mapMutations([
            'enabledFullScreenLoading',
            'addDataNote',
            'clearDataNote',
            'impact/sale'
        ]),
        ...mapActions([
            'verifyPixelFinishBuy',
            'updateNoteInvoice'
        ]),
        goRedir(){
            this.clickRedir = true;

            Helper.setPixelBuilderallFinishPayment(null, null, true);
            
            setTimeout(() => location.href = this.redirectUrl, 1500);
            
        },
        async copyBarcode(text){
            (await this.$copyText(text));

            this.$Notify({
                title: 'Sucesso',
                message: 'Codigo de barra copiado com sucesso',
                type: 'success'
            });
        },
        openViewBoleto(){
            window.open(this.boleto.downloadPdf, '_blank');
        },
        createUrlRedirect(){
            if(this.$route.query['wp_upgrade_trial'] !== undefined && this.paymentMethodSelected != 'pagSeguro'){
                this.redirectUrl = 'https://wordpress.builderall.com/clouds/create'; 
                return;
            }
            
            if(localStorage.getItem('canSkipStep') && localStorage.getItem('redirectUrlAfterPayment')){
                let url = localStorage.getItem('redirectUrlAfterPayment');

                localStorage.removeItem('canSkipStep');
                localStorage.removeItem('redirectUrlAfterPayment');

                this.redirectUrl = url; return;
            }

            if(this.$route.query['go-to-purchase-domain'] !== undefined){
                let url = Helper.getUrlOffice('office/buy-domain?return-cheetah=1');
                url = Helper.setUrlParam(url, 'aid', this.userFather.id);

                if(this.userFather.hasOwnProperty('subdomain')) {
                    url = Helper.setUrlParam(url, 'sd', this.userFather.subdomain);
                }

                this.redirectUrl = url; return;
            }

            if(this.$route.query['return-cheetah'] !== undefined){
                let url = Helper.getUrlOffice('office/cheetah-builder');
                url = Helper.setUrlParam(url, 'aid', this.userFather.id);

                if(this.userFather.hasOwnProperty('subdomain')) {
                    url = Helper.setUrlParam(url, 'sd', this.userFather.subdomain);
                }

                this.redirectUrl = url; return;
            }

            if(this.getSlug == 'email-marketing-mastery'){
                let url = this.product.redirect;
                url = Helper.setUrlParam(url, 'iduser', this.user.user_identifier);
                url = Helper.setUrlParam(url, 'hash', this.bgResponse.payment.gateway.gateway_identifier);
                
                this.redirectUrl = url; return;
            }

            let url = this.product.redirect ? this.product.redirect : this.urlOfficeUser;
            url = Helper.setUrlParam(url, 'aid', this.userFather.id);

            if(this.userFather.hasOwnProperty('subdomain')) {
                url = Helper.setUrlParam(url, 'sd', this.userFather.subdomain);
            }

            this.redirectUrl = url; return;
        },
        startTimeRedirectUser(){
            let time = setInterval(() => {
                this.timeRedirect -= 1;
                
                if(this.timeRedirect == 0){
                    clearInterval(time);
                    this.goRedir();
                }
            }, 1000);
        },
        async verifyContactSupport(){
            let env = ['dev', 'test'].includes(Helper.getEnvs().env) ? 'io' : 'com';
            
            let dataSupport = (await axios.get(`https://ba-support.builderall.${env}/checkout/${cookie.get('cs_code')}`)).data;
            
            this.addDataNote({
                nota: {
                    support: dataSupport
                }
            });

            cookie.remove('cs_code');
        },
        async verifyPaymentIsBoleto(){
            if(this.paymentMethodSelected == 'boleto'){
                this.$httpOffice.post(`${this.endpointOffice.UPDATE_INVOICE}/${this.fatura.invoice.id}`, {
                    recorrente: 0,
                    recorrente_ativo: 0
                });
            }
        },
        async verifyPixelPurchase(){
            if(this.isPaymentDomain || this.isPaymentStorage || this.isPaymentDomainExtraConnection) return;

            let itemName = (this.isSponsorBuilderall ? 'Direct-Purchase: ' : 'Affiliate-Purchase: ') + this.product.title;
            let eventName = {
                purchase: 'purchase',
                trialPurchase: 'trial_purchase'
            };

            let campaign = this.$route.query['campaign'] ? (this.$route.query['campaign']).toString().toUpperCase() : null;

            if(campaign){
                itemName = `(C-${campaign}) ` + itemName;
                eventName.purchase = `C_${campaign}__${eventName.purchase}`;
                eventName.trialPurchase = `C_${campaign}__${eventName.trialPurchase}`;
            }

            if(!this.user.first_payment){
                console.log(`Entrou no evento de ${this.product.isTrialDays ? `${eventName.trialPurchase}` : `${eventName.purchase}`}`);

                //Para casos, que o pixel do facebook ja vem inserido atraves do Google Tag Manager
                PixelAnalytics.createEventPixel('fb', this.product.isTrialDays ? 'StartTrial' : 'Purchase', {
                    content_name: this.product.title,
                    currency: this.product.currency,
                    value: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
                });

                let amountEvent = this.product.isTrialDays
                    ? this.product.trialDaysAmount
                    : this.product.amountCoupon ? this.product.amountCoupon : this.product.amount;

                PixelAnalytics.createEventPixel('ga', this.product.isTrialDays ? eventName.trialPurchase : eventName.purchase, {
                    hitType: 'event',
                    eventCategory: this.isSponsorBuilderall ? 'direct_purchase' : 'affiliate_purchase',
                    eventAction: 'click',
                    currency: this.product.currency,
                    transaction_id: sessionStorage.getItem('idInvoice'),
                    value: amountEvent,
                    plan: this.product.title,
                    items: [{
                        item_id: this.product.id,
                        item_name: itemName,
                        currency: this.product.currency,
                        price: amountEvent,
                        quantity: 1
                    }]
                });
            }
        },
        verifySalesParam(){
            if(this.$route.query['sales'] !== undefined){
                this.addDataNote({
                    nota: {
                        sales: this.$route.query['sales']
                    }
                });

                this.updateNoteInvoice();
            }
        }
    },
    components: {
        Box
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    .paymentConfirmation_header{
        text-align: center;
    }
    .paymentConfirmation_header_title{
        margin-bottom: 20px;

        & h2{
            display: inline-block;
            font-size: 1.5em;
            color: var(--white);
            background: #1869F6;
            padding: 5px 30px;
            border-radius: 8px;
        }

        & .icon{
            margin-right: 8px;
        }

    }
    .paymentConfirmation_header_desc{
        width: 650px;
        margin: auto;
        font-weight: 600;
    }

    .paymentConfirmation_content_normal{
        text-align: center;
        margin-top: 30px;
    }
    .paymentConfirmation_content_normal_text{
        color: #1869F6;
        font-weight: bold;
    }
    .paymentConfirmation_content_normal_time{
        width: 93px;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        background: #1869F6;
        font-size: 3em;
        font-weight: 600;
        padding: 10px 30px;
        border-radius: 50%;
        margin: auto;
        margin-top: 15px;
    }
    .paymentConfirmation_content_normal_iconLoading{
        width: 93px;
        margin-top: 15px;
    }

    .paymentConfirmation_content_boleto{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .paymentConfirmation_content_boleto_barCode{
        text-align: center;
    }

    .paymentConfirmation_content_boleto_actions{
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        width: 600px;
        justify-content: space-between;
        border-top: 2px dashed #C0C0C0;
        flex-wrap: wrap;

        & img{
            margin: auto;
        }
    }

    .paymentConfirmation_content_boleto_actions_action{
        width: 45%;
        text-align: center;
        font-size: 0.9em;
        background: var(--blue);
        border-radius: 5px;
        color: var(--white);
        margin-bottom: 20px;
        cursor: pointer;
        padding: 10px;

        &.boletoView{
            background: #2A2A2A;
        }

        &.boletoAction{
            margin: auto;
        }
    }

    @include media(sm,md,lg){
        .paymentConfirmation_header_desc{
            width: auto;
        }

        .paymentConfirmation_content_boleto_actions{
            width: auto;
        }
        .paymentConfirmation_content_boleto_actions_action{
            &.boletoAction{
                width: 100%;
            }
        }
    }

</style>