<template>
    <div class="messagesSecurity">
        <p v-if="isPaymentDomainRegistry" class="messagesSecurity_text domainRegistry">
            {{ translateWord('Você está comprando o dominio: {0}. e poderá utilizá-lo por {1} ano, após este período será necessário efetuar o pagamento para renovação',
            [
            product.domain,
            product.domainTime
            ]) }}

            <!--{{ translateWord('Entendo que estou realizando o pagamento de {0} {1} e que o mesmo deverá ser renovado anualmente',
            [
                product.currencySymbol,
                product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount,
            ]) }}-->
        </p>

        <p v-else-if="isPaymentDomain || isPaymentDomainExtraConnection" class="messagesSecurity_text">
            {{ translateWord('Entendo que estou realizando o pagamento de {0} {1} e que o mesmo deverá ser renovado anualmente',
            [
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount,
            ]) }}
        </p>

        <p v-else-if="product.isTrialDays && !product.isAmountFirstMonth" class="messagesSecurity_text">
            {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {1}, apos o período de {2} dias de teste. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo',
            [
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount,
            product.trialDays
            ]) }}
        </p>

        <p v-else-if="hasCouponAndIsValid && coupon.periodTime" class="messagesSecurity_text">
            {{ translateWord('Entendo que estou assinando a plataforma Builderall e que durante {0} meses meu cartão será cobrado {1} {2} e após esse período meu cartão começará a ser cobrado {1} {3}. Reconheço que posso cancelar a qualquer momento acessando a área de cobrança em meu conta ou criando um ticket ou entrando em contato com nossa equipe de suporte via chat ao vivo.',
            [
            coupon.periodTime,
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amountCoupon,
            product.isTheCurrency.eur ? productAmountPlusVatFee(true) : product.amount,

            ]) }}
        </p>

        <p v-else-if="hasCouponAndIsValid && !product.isAmountFirstMonth" class="messagesSecurity_text">
            {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {2}. e na recorrência o valor de {0} {1}. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo',
            [
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amountCoupon,
            product.isTheCurrency.eur ? productAmountPlusVatFee(true) : product.amount,

            ]) }}
        </p>

        <p v-else-if="product.isAmountFirstMonth" class="messagesSecurity_text">
            {{ translateWord('Entendo que o Primeiro pagamento do mês sera de {0} {1}, depois de 30 dias o valor de {2} {3} começa a ser debitado na recorrência',
            [
            product.currencySymbol,
            (hasCouponAndIsValid && product.isTheCurrency.eur ? productAmountPlusVatFee(true) : (product.isTheCurrency.eur ? productAmountPlusVatFee(false, false, true) : (hasCouponAndIsValid ? product.amountCoupon : product.amountFirstMonth))),
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount
            ]) }}
        </p>

        <p v-else class="messagesSecurity_text">
            {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {1}. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo',
            [
            product.currencySymbol,
            product.isTheCurrency.eur ? productAmountPlusVatFee : product.amount
            ]) }}
        </p>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    name: "MessagesSecurity",
    computed: {
        ...mapState([
            'isPaymentDomain',
            'isPaymentDomainExtraConnection',
            'isPaymentDomainRegistry',
            'coupon'
        ]),
        ...mapGetters([
            'translateWord',
            'product',
            'productAmountPlusVatFee',
            'hasCouponAndIsValid'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .messagesSecurity_text{
        font-size: 0.7em;
        
        &.domainRegistry{
            margin-top: 15px;
            text-align: center;
        }
    }
</style>