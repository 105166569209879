<template>
    <div class="accountNotLoggedIn">
        <template v-if="!isViewLogin">

            <!-- Type Account Europe -->
            <div v-if="product.isTheCurrency.eur" class="accountNotLoggedIn_typeAccountEur">
                <div class="accountNotLoggedIn_typeAccountEur_container">
                    <div :class="{active: typeAccountEur.personal}" @click="changeTypeAccountEur('personal')" class="accountNotLoggedIn_typeAccountEur_type accountNotLoggedIn_typeAccountEur_business">{{ translateWord('Conta pessoal') }}</div>
                    <div :class="{active: typeAccountEur.business}" @click="changeTypeAccountEur('business')" class="accountNotLoggedIn_typeAccountEur_type accountNotLoggedIn_typeAccountEur_personal">{{ translateWord('Conta empresa') }}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-24"><h1 class="accountNotLoggedIn_title">{{ translateWord('Novo cadastro') }}</h1></div>
            </div>

            <form id="formRegister" novalidate>
                <div class="row">
                    <div class="col-24">
                        <at-input name="name" :status="$v.registerUser.name.$dirty && $v.registerUser.name.$invalid ? 'error' : ''" v-model="$v.registerUser.name.$model" :placeholder="translateWord('Digite seu nome e sobrenome')">
                            <template #append>
                                <span class="icon icon-user"></span>
                            </template>
                        </at-input>

                        <p class="invalidInputText" v-if="$v.registerUser.name.$dirty && $v.registerUser.name.$invalid">{{ translateWord('Coloque seu nome completo') }}</p>
                    </div>

                    <div class="col-24">
                        <at-input name="email" :status="$v.registerUser.email.$dirty && $v.registerUser.email.$invalid ? 'error' : ''" v-model="$v.registerUser.email.$model" :placeholder="translateWord('Seu E-mail')" type="email">
                            <template #append>
                                <span class="icon icon-mail"></span>
                            </template>
                        </at-input>

                        <p class="invalidInputText" v-if="$v.registerUser.email.$dirty && $v.registerUser.email.$invalid">{{ translateWord('Coloque um e-mail valido') }}</p>
                    </div>

                    <div class="col-24">
                        <at-input name="email" v-model="registerUser.emailConfirm" :placeholder="translateWord('Confirmar E-mail')" type="email">
                            <template #append>
                                <span class="icon icon-mail"></span>
                            </template>
                        </at-input>
                    </div>

                    <div class="col-24">
                        <at-input name="password" v-model="registerUser.password" type="password" :placeholder="translateWord('Sua Senha')">
                            <template #append>
                                <span class="icon icon-unlock"></span>
                            </template>
                        </at-input>
                    </div>

                    <div class="col-24">
                        <at-input name="password" v-model="registerUser.passwordConfirm" :placeholder="translateWord('Confirmar Senha')" type="password">
                            <template #append>
                                <span class="icon icon-unlock"></span>
                            </template>
                        </at-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-10">
                        <at-select filterable v-model="registerUser.country" :placeholder="translateWord('País')">
                            <at-option v-for="(country, index) in countries" :key="index" :value="country.slug">{{ country.country }} ({{ country.iso_code }})</at-option>
                        </at-select>
                    </div>

                    <div v-if="product.isTheCurrency.eur && typeAccountEur.business" class="col-14">
                        <at-input name="vatNumber" v-model="registerUser.vatNumber" :placeholder="translateWord('VAT Número')"></at-input>
                    </div>

                    <div :class="{'col-24': product.isTheCurrency.eur && typeAccountEur.business}" class="col-14">
                        <vue-tel-input :inputOptions="{placeholder: translateWord('Seu Telefone')}" :preferredCountries="['US', 'BR']" @input="changePhone">
                            <span slot="arrow-icon"><<</span>
                        </vue-tel-input>
                    </div>
                </div>

                <div class="accountNotLoggedIn_termsAndPrivacy">
                    <div class="accountNotLoggedIn_termsAndPrivacy_newsLatter">
                        <at-checkbox v-model="newsletter" name="newsletter">
                            <span>{{ translateWord('Envie-me e-mails com notícias e ofertas') }}</span>
                        </at-checkbox>
                    </div>

                    <div class="accountNotLoggedIn_termsAndPrivacy_privacy">
                        <at-checkbox v-model="privacy" name="privacy">
                            <a target="_blank" :href="getLinkPrivacy" :class="privacyClass">{{ translateWord('Eu aceito a politica de privacidade') }}</a>
                        </at-checkbox>
                    </div>

                    <div class="accountNotLoggedIn_termsAndPrivacy_terms">
                        <at-checkbox v-model="terms" name="terms">
                            <a target="_blank" :href="getLinkTerms">{{ translateWord('Eu aceito os termos de uso') }}</a>
                        </at-checkbox>
                    </div>
                </div>
            </form>

            <div class="accountNotLoggedIn_actions">
                <at-button class="btn_formWidth" @click.prevent="submitUiBenda" id="btnRegister" type="primary" :disabled="btnEnabledRegister">{{ translateWord('Criar nova conta') }}</at-button>

                <p class="accountNotLoggedIn_actions_login" @click.prevent="isViewLogin = true">{{ translateWord('Entrar com uma conta existente') }}</p>
            </div>
        </template>

        <template v-if="isViewLogin">
            <div class="row">
                <div class="col-24"><h1 class="accountNotLoggedIn_title">{{ translateWord('Efetuar login') }}</h1></div>
            </div>

            <div class="row">
                <div class="col-24">
                    <at-input name="email" :status="$v.loginOffice.email.$dirty && $v.loginOffice.email.$invalid ? 'error' : ''" v-model="$v.loginOffice.email.$model" :placeholder="translateWord('Seu E-mail')" type="email">
                        <template #append>
                            <span class="icon icon-mail"></span>
                        </template>
                    </at-input>

                    <p class="invalidInputText" v-if="$v.loginOffice.email.$dirty && $v.loginOffice.email.$invalid">{{ translateWord('Coloque um e-mail valido') }}</p>
                </div>

                <div class="col-24">
                    <at-input name="name" v-model="loginOffice.password" :placeholder="translateWord('Senha')" type="password">
                        <template #append>
                            <span class="icon icon-unlock"></span>
                        </template>
                    </at-input>
                </div>
            </div>
            <div class="accountNotLoggedIn_actions">
                <at-button class="btn_formWidth" type="primary" @click.prevent="login" :disabled="btnEnabledLogin">{{ translateWord('Logar') }}</at-button>

                <p class="accountNotLoggedIn_actions_login" @click.prevent="isViewLogin = false">{{ translateWord('Registrar uma nova conta') }}</p>
            </div>
        </template>
    </div>
</template>

<script>
import Helper from '../../assets/js/helper';
import PixelAnalytics from '../../assets/js/PixelAnalytics';
import RecoverPassword from '../User/RecoverPassword';
import { email, required } from 'vuelidate/lib/validators';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
    name: "AccountNotLoggedIn",
    data(){
        return {
            countries: [],
            registerUser: {
                name: '',
                email: '',
                emailConfirm: '',
                password: '',
                passwordConfirm: '',
                phone: '',
                country: '',
                iubendaConsent: '',
                vatNumber: null
            },
            loginOffice: {
                email: '',
                password: ''
            },
            terms: false,
            privacy: false,
            newsletter: false,
            isViewLogin: false,
            isViewRecoverPassword: false,
            viewAlertRecoverPassword: false,
            alertTextRecoverPassword: '',
            privacyClass: '',
            typeAccountEur: {
                personal: true,
                business: false
            }
        }
    },
    validations: {
        registerUser:{
            name: { fullName: (value) => /^[a-zA-Z]{2,}\s+([a-zA-Z\.]{2,})?.*([a-zA-Z\.]{2,})$.*/g.test(value) },
            email: {
                required, email
            }
        },
        loginOffice:{
            email: {
                required, email
            }
        }
    },
    async created(){
        if(['dev', 'test'].includes(Helper.getEnvs().env)){
            this.registerUser.vatNumber = 'EL078909371';
        }

        if(PixelAnalytics.fbData.accessToken){
            let pixelFbInitiateRegistration = await generateFacebookEventId('InitiateRegistration');

            sendInitiateRegistrationEvent(
                PixelAnalytics.fbData.accessToken,
                PixelAnalytics.fbData.id,
                PixelAnalytics.fbData.funnelsDomain,
                pixelFbInitiateRegistration,
                PixelAnalytics.fbData.ur,
                PixelAnalytics.fbData.subdomain
            );
        }

        this.insertUiBenda();

        this.countries = (await this.$httpOffice.get(`${this.endpointOffice.SUBSIDIARIES}`)).data.data;

        this.firstListCountries();
    },
    computed: {
        ...mapState([
            'endpointOffice',
            'localeUser',
            'userFather',
            'isPlan'
        ]),
        ...mapGetters([
            'translateWord' ,
            'getAllParamsUrl',
            'product'
        ]),
        btnEnabledRegister(){
            return this.$v.registerUser.email.$invalid || this.$v.registerUser.name.$invalid || this.registerUser.name == '' || this.registerUser.email == '' || this.registerUser.password == '' || !this.terms || !this.privacy ? true : false;
        },
        btnEnabledLogin(){
            return this.loginOffice.email == '' || this.loginOffice.password == '' ? true : false;
        },
        getLinkTerms(){
            let lang = Helper.convertSigla(Helper.getLangBrowser());

            switch(lang){
                case 'en': return 'https://builderall.com/terms-of-use'; break;
                case 'pt': return 'https://br.builderall.com/termos-uso'; break;
                case 'es': return 'https://es.builderall.com/terminos-de-uso'; break;
                case 'it': return 'https://it.builderall.com/termini-di-uso'; break;
                case 'fr': return 'https://fr.builderall.com/conditions-d-utilisation'; break;
                case 'de': return 'https://builderall.de/nutzungsbedingungen'; break;
                case 'nl': return 'https://nl.builderall.com/algemene-gebruiksvoorwaarden'; break;
                case 'pl': return 'https://pl.builderall.com/warunki-uytkowania-builderall'; break;
                case 'iw': return 'https://il.builderall.com/terms-of-use'; break;
                default: return 'https://builderall.com/terms-of-use';
            }
        },
        getLinkPrivacy(){
            let lang = Helper.convertSigla(Helper.getLangBrowser());
            let link = null;

            switch(lang){
                case 'en': link = 'https://www.iubenda.com/privacy-policy/15328257'; break;
                case 'pt': link = 'https://www.iubenda.com/privacy-policy/36736122'; break;
                case 'es': link = 'https://www.iubenda.com/privacy-policy/19349540'; break;
                case 'it': link = 'https://www.iubenda.com/privacy-policy/57742374'; break;
                case 'fr': link = 'https://www.iubenda.com/privacy-policy/12277444'; break;
                case 'de': link = 'https://www.iubenda.com/privacy-policy/76100068'; break;
                case 'nl': link = 'https://www.iubenda.com/privacy-policy/25723324'; break;
                case 'pl': link = 'https://pl.builderall.com/polityka-prywatnoci'; break;
                case 'iw': link = 'https://il.builderall.com/privacy-policy'; break;
                default: link = 'https://builderall.com/privacy-policy'; break;
            }

            let iubendaLangs = ['en','pt','es','it','fr','de','nl'];
            if(iubendaLangs.includes(lang)) {
                this.privacyClass = 'iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe';

                let script  = document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', 'https://cdn.iubenda.com/iubenda.js');
                document.head.appendChild(script);
            }

            return link;
        },
    },
    methods: {
        ...mapMutations([
            'enabledFullScreenLoading',
            'impact/lead',
        ]),
        changePhone(phone, phoneCompleted){
            this.registerUser.phone = `${phoneCompleted.country.dialCode} ${phone}`;
        },
        changeTypeAccountEur(type){
            this.typeAccountEur = Object.fromEntries(Object.entries(this.typeAccountEur).map((value, key) => [value[0], false]));

            this.typeAccountEur[type] = true;
        },
        async register(){
            try{
                let dataRegister = {
                    cadastro_nome: this.registerUser.name,
                    cadastro_email: this.registerUser.email,
                    cadastro_senha: this.registerUser.password,
                    cadastro_telefone: this.registerUser.phone,
                    subsidiary: this.registerUser.country,
                    tracking_origin: window.location.hostname,
                    tracking_params: `p1=rd&p2=https://${window.location.hostname}${window.location.search.replace('?', '&')}`,
                    iubenda_consent: this.registerUser.iubendaConsent,
                    form_origin: 'ba-gateway',
                    id_plan: this.product.id
                };
                
                let aid = Helper.getAidOffice() || this.$route.query.aid || this.userFather.id || null;
                let tagInternals = this.$route.query.taginternals || null;
                
                let sd = this.$route.query.sd || 'checkout';
                dataRegister.tracking_params += `&sd=${sd}`;
                
                if(aid) dataRegister['cadastro_aid'] = aid;
                if(tagInternals) dataRegister['taginternals'] = tagInternals;
                if(this.registerUser.vatNumber) dataRegister['vat_number'] = this.registerUser.vatNumber;


                let itemName = (this.isSponsorBuilderall ? 'Direct-Purchase: ' : 'Affiliate-Purchase: ') + this.product.title;
                let eventName = {
                    lead: 'lead'
                };
                let campaign = this.$route.query['campaign'] ? (this.$route.query['campaign']).toString().toUpperCase() : null;

                let registerUser = (await this.$httpOffice.post(`${Helper.getUrlOffice('office/create')}${this.getAllParamsUrl(false)}`, Helper.serialize(dataRegister), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;
                
                if(registerUser.hasOwnProperty('error') && registerUser['error'] == 'email_already_exists'){
                    this.enabledFullScreenLoading({status: false});
                    this.$Notify.info(this.translateWord('Opsss... Este e-mail já está cadastrado. Efetue o login'));
                    
                    return;
                }
                
                if(registerUser['type'] == 'success') {
                    PixelAnalytics.addDataLayer('NewRegister');

                    this['impact/lead']({
                        id: registerUser['user']['id'],
                        email: registerUser['user']['email']
                    });

                    if(PixelAnalytics.fbData.accessToken){
                        let pixelFbLead = await generateFacebookEventId('Lead');
                        
                        await sendLeadEvent(
                            PixelAnalytics.fbData.accessToken,
                            PixelAnalytics.fbData.id,
                            PixelAnalytics.fbData.funnelsDomain,
                            pixelFbLead,
                            Helper.firstName(this.registerUser.name),
                            Helper.lastName(this.registerUser.name),
                            this.registerUser.email,
                            PixelAnalytics.fbData.ur,
                            PixelAnalytics.fbData.subdomain
                        );
                    }

                    if(campaign){
                        itemName = `(C-${campaign}) ` + itemName;
                        eventName.lead = `C_${campaign}__${eventName.lead}`;
                    }

                    gtag('event', eventName.lead, { hitType: 'event', eventAction: 'click'});

                    this.enabledFullScreenLoading({status: true, text: this.translateWord('Cadastro feito com sucesso')});
                    
                    PixelAnalytics.createEventPixel('fb', 'Lead', {
                        content_name: this.product.title,
                        currency: this.product.currency,
                        value: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
                    });

                    setTimeout(() => window.location.reload() , 2000);
                    
                    return;
                }

                PixelAnalytics.addDataLayer('RegistrationFailure');
                
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('Opsss... Infelizmente ocorreu um erro ao criar sua conta na Builderall, favor tente mais tarde!'));
            }catch(error){
                console.log(error);

                PixelAnalytics.addDataLayer('RegistrationFailure');

                this.enabledFullScreenLoading({status: false});

                this.$Notify.info(this.translateWord('Opsss... Infelizmente ocorreu um erro ao criar sua conta na Builderall, favor tente mais tarde!'));
            }
        },
        async login(){
            this.enabledFullScreenLoading({status: true, text: this.translateWord('Aguarde efetuando login')});

            try{
                let loginUser = (await this.$httpOffice.post(this.endpointOffice.LOGIN, Helper.serialize({
                    email: this.loginOffice.email,
                    senha: this.loginOffice.password
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;

                if(loginUser['type'] == 'success') {
                    PixelAnalytics.addDataLayer('SuccessfullLoggedIn');
                    
                    this.enabledFullScreenLoading({status: true, text: this.translateWord('Login feito com sucesso')});

                    window.location.reload();

                    return this.$emit('new-register:login-success', true);
                }
                
                PixelAnalytics.addDataLayer('FailedToLogin');
                
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('Opsss... Infelizmente ocorreu um erro ao logar no office, favor tente mais tarde!'));
                console.log(loginUser);
                
            }catch(error){
                
                PixelAnalytics.addDataLayer('FailedToLogin');
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('Opsss... Infelizmente ocorreu um erro ao logar no office, favor tente mais tarde!'));
                
                console.log(error);
            }
        },
        backRecoverPassword({status, email}){
            this.viewAlertRecoverPassword = false;

            //Quando usuario clicar em voltar.
            if(!status) {
                this.isViewLogin = true;
                this.isViewRecoverPassword = false;
                return;
            }

            this.alertTextRecoverPassword = this.translateWord('E-mail enviado com sucesso para redefinir senha. {0}', [email]);
            this.isViewLogin = true;
            this.isViewRecoverPassword = false;
            this.viewAlertRecoverPassword = true;
        },
        insertUiBenda(){
            _iub.cons_instructions.push([
                "init", {api_key: Helper.getEnvs().uiBendaKeys.publicKey}
            ]);

            let script  = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', 'https://cdn.iubenda.com/cons/iubenda_cons.js');
            document.head.appendChild(script);
        },
        submitUiBenda(){
            this.enabledFullScreenLoading({status: true, text: this.translateWord('Aguarde, estamos realizando seu cadastro')});

            if(!this.terms){
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('É necessario aceitar os termos de uso para realizar o cadastro'))
                return;
            }

            if(this.registerUser.email != this.registerUser.emailConfirm){
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('O E-mail de confirmação esta incorreto'));
                return;
            }

            if(this.registerUser.password != this.registerUser.passwordConfirm){
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('Sua confirmação de senha esta incorreta'));
                return;
            }

            if(this.product.isTheCurrency.eur && this.typeAccountEur.business && !this.registerUser.vatNumber){
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('Para continuar o cadastro com uma conta empresa, favor inserir um número de VAT válido.'));
                return;
            }

            if(this.product.isTheCurrency.eur && this.typeAccountEur.business && !Helper.validateVat(this.registerUser.vatNumber)){
                this.enabledFullScreenLoading({status: false});
                this.$Notify.info(this.translateWord('O Número de VAT {0} não é um formato válido. Favor verificar o número e tente novamente.', [this.registerUser.vatNumber]));
                return;
            }

            _iub.cons_instructions.push(['submit',
                {
                    submitElement: document.getElementById('btnRegister'),
                    form: {
                        selector: document.getElementById('formRegister'),
                        map: {
                            subject: {
                                full_name: 'name', // name
                                email: 'email'   // name
                            },
                            preferences: {
                                term: 'terms',
                                privacy_policy: 'privacy'
                            },
                            exclude: ['password']
                        }
                    },
                    consent: {
                        legal_notices: [
                            {
                                identifier: 'terms'
                            },
                            {
                                identifier: 'privacy_policy'
                            },
                            {
                                identifier: 'cookie_policy'
                            }
                        ],
                    },
                },
                {
                    success: (response) => {
                        this.registerUser.iubendaConsent = response.status == 200 ? response.responseText : JSON.stringify(response);
                        this.register();
                    },
                    error: (response) => {
                        this.registerUser.iubendaConsent = JSON.stringify(response);
                        this.register();
                    }
                }
            ]);
        },
        firstListCountries(){
            let [countryUs, countryBr] = this.countries.filter((country, index) => {
                if(country.iso_code == 'US' || country.iso_code == 'BR'){
                    this.countries.splice(index, 1);
                    return true;
                }
            });

            this.countries.unshift(countryUs);
            this.countries.unshift(countryBr);
        }
    },
    components: {
        RecoverPassword,
        VueTelInput
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .accountNotLoggedIn_typeAccountEur{
        display: flex;
        justify-content: center;
        margin-bottom: 28px;
    }
    .accountNotLoggedIn_typeAccountEur_container{
        display: flex;
        text-align: center;
        padding: 6px;
        border: 1px solid var(--gray-500);
        border-radius: 50px;
        font-size: 0.6em;
    }
    .accountNotLoggedIn_typeAccountEur_type{
        margin-right: 12px;
        padding: 6px 30px;
        cursor: pointer;
        font-weight: 600;
        color: var(--gray-500);

        &.active{
            background: var(--blue);
            color: var(--white);
            border-radius: 50px;
        }

        &:last-child{
            margin-right: 0px;
        }
    }

    .accountNotLoggedIn_title{
        font-size: 1.2em;
        color: var(--gray);
        text-align: left;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .accountNotLoggedIn .at-checkbox__inner{
        width: 12px;
        height: 12px;
    }
    .accountNotLoggedIn_termsAndPrivacy_newsLatter{
        & span{
            font-size: 9px;
            color: var(--gray-500);
        }
    }
    .accountNotLoggedIn_termsAndPrivacy{
        display: flex;
        justify-content: center;
        margin: 20px 5px;

        & .at-checkbox{
            display: flex;
        }

        & .at-checkbox__label{
            font-size: inherit;
        }
    }
    .accountNotLoggedIn_termsAndPrivacy_terms,
    .accountNotLoggedIn_termsAndPrivacy_privacy,
    .accountNotLoggedIn_termsAndPrivacy_newsLatter{
        margin-right: 10px;

        & a{
            font-size: 9px;
        }
    }

    .accountNotLoggedIn_actions{
        text-align: center;
    }
    .accountNotLoggedIn_actions_login{
        font-size: 0.8em;
        margin-top: 20px;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    }

    .accountNotLoggedIn_stamps{
        border-top: 2px solid var(--gray-100);
        padding: 20px 10px;
        margin-top: 40px;
    }
</style>