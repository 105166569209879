<template>
    <div class="paymentComplements">

        <!-- DetailsPlanOrFee -->
        <div class="paymentComplements_cart">
            <DetailsPlanOrFee :show-total-value="false" :show-change-period="false" />
        </div>

        <!-- total -->
        <div v-if="!isPaymentDomainRegistry" class="paymentComplements_total">
            <div class="paymentComplements_total_header">
                <div class="paymentComplements_total_header_name">
                    <p>{{ translateWord('Total') }}</p>
                </div>
            </div>

            <div class="paymentComplements_total_total">
                <p v-if="product.isTrialDays" class="paymentComplements_total_total_trialDays">
                    <span>
                        <del>
                            {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                        </del>
                    </span> 
                    <span class="amountEmphasis">
                        {{ product.currencySymbol }}{{ product.trialDaysAmount }}
                    </span>
                    <span class="periodSymbol">
                        {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                    </span>
                </p>

                <p v-else-if="hasCouponAndIsValid && !product.isAmountFirstMonth">
                    <span>
                        <del>
                            {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                        </del>
                    </span>
                    <span class="periodSymbol">
                        {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                    </span>
                </p>

                <p v-else-if="product.isAmountFirstMonth" class="amountEmphasis">
                    <span>
                        {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                    </span>
                    <span class="periodSymbol">
                        {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                    </span>
                </p>

                <p v-else class="amountEmphasis">
                    <span>
                        {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee() : product.amount }}
                    </span>
                    <span class="periodSymbol">
                        {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                    </span>
                </p>
            </div>


            <!-- Vat -->
            <div v-if="product.isTheCurrency.eur && isChangeVat" class="paymentComplements_total_item">
                <div class="planOrFeeComplements_values_item_name">
                    {{ translateWord('Taxa de VAT') }}
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p>{{ vatFee.percentage }}% ({{ vatFee.isValid ? vatFee.vatNumberCompleted : localeUser.country }})</p>
                </div>
            </div>

            <!-- Cupom de desconto -->
            <div v-if="hasCouponAndIsValid && !product.isAmountFirstMonth" class="paymentComplements_total_item paymentComplements_total_coupon">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ coupon.code }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p class="amountEmphasis">{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(true) : couponFormatAmount }}</p>
                </div>
            </div>

            <!-- Trial Days -->
            <div v-if="product.isTrialDays && !product.isAmountFirstMonth" class="paymentComplements_total_item paymentComplements_total_trialDays">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ translateWord('Dias de teste') }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p>{{ product.trialDays }} {{ translateWord('Dias') }}</p>
                </div>
            </div>

            <!-- Valor no primeiro mes -->
            <div v-if="product.isAmountFirstMonth" class="paymentComplements_total_item planOrFeeComplements_values_itemAmountFirstMonth">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ translateWord('Primeiro mês') }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p v-if="hasCouponAndIsValid">
                        <del>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, false, true) : product.amountFirstMonth }}</del>
                        {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(true) : product.amountCoupon }}
                    </p>
                    <p v-else>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, false, true) : product.amountFirstMonth }}</p>
                </div>
            </div>
        </div>

        <!-- Domain -->
        <div v-if="isPaymentDomainRegistry" class="paymentComplements_domain">
            <DetailsDomain />
        </div>

        <!-- MessagesSecurity -->
        <div v-if="isPlan">
            <MessagesSecurity />
        </div>

        <template v-if="!isPaymentDomainRegistry">
            <div v-if="!['63', '64', '65'].includes(product.id)" class="paymentComplements_stamps">
                <Stamps />
            </div>

            <!-- Coupon -->
            <div class="paymentComplements_coupon">
                <Coupon :not-remove="true" />
            </div>

            <!-- Sponsor -->
            <div class="paymentComplements_sponsor" v-if="Object.keys(userFather).length !== 0 && !isSponsorBuilderall">
                <Sponsor/>
            </div>
        </template>

        <!-- voltar -->
        <p class="paymentComplements_back" @click="changeStepAction('prev')">< {{ translateWord('Voltar') }}</p>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import Helper from '../assets/js/helper';
import Sponsor from '../components/User/Sponsor';
import Coupon from '../components/Coupon';
import Stamps from '../components/Stamps';
import MessagesSecurity from '../components/MessagesSecurity';
import DetailsPlanOrFee from '../components/Checkout/DetailsPlanOrFee'
import DetailsDomain from '../components/Checkout/DetailsDomain'

export default {
    name: "PaymentComplements",
    data(){
        return {

        }
    },
    computed: {
        ...mapState([
            'userFather',
            'coupon',
            'vatFee',
            'localeUser',
            'isPaymentDomainRegistry',
            'isPlan'
        ]),
        ...mapGetters([
            'product',
            'hasCouponAndIsValid',
            'translateWord',
            'isSponsorBuilderall',
            'isChangeVat',
            'productAmountPlusVatFee'
        ]),
        couponFormatAmount(){
            return Helper.calcDiscount(this.product.amount, this.coupon.value, this.coupon.type)
        },
    },
    methods: {
        ...mapMutations([
            'changeStepAction'
        ])
    },
    components: {
        Sponsor,
        Coupon,
        Stamps,
        MessagesSecurity,
        DetailsPlanOrFee,
        DetailsDomain
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    .paymentComplements{
        & > *{
            margin-bottom: 30px;
        }
    }

    .paymentComplements_total{
        @extend %cart;
    }
    .paymentComplements_total_header{
        @extend %cartHeader;
    }
    .paymentComplements_total_header_name{
        @extend %cartHeaderName;
    }

    .paymentComplements_back{
        color: var(--gray);
        cursor: pointer;
        font-weight: 600;
        font-size: 0.8em;
    }

    .paymentComplements_domain{
        margin-top: -11px;
        border-top: 1px solid var(--gray-100);
        border-bottom: 2px solid var(--gray-100);
        padding: 32px 10px 32px 10px;
    }
    .paymentComplements_coupon,
    .paymentComplements_stamps{
        margin-top: 30px;
        padding-top: 40px;
        border-top: 1px solid var(--gray-100);
    }

    .paymentComplements_sponsor{
        margin-top: 30px;
        padding-top: 40px;
    }

    .paymentComplements_total_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;
        font-weight: 600;
        color: #505050;
        padding: 20px 10px;
    }

    .paymentComplements_total_total{
        font-size: 1em;
        font-weight: 600;
        color: #505050;
        padding: 20px 10px;

        & .amountEmphasis{
            font-size: 2em;
        }
    }

    .paymentComplements_total_total .paymentComplements_total_total_trialDays{
        & del{
            font-size: 0.7em;
            color: var(--gray-100);
        }
    }

</style>