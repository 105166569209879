<template>
    <div class="coupon">

        <p v-if="hasCouponAndIsValid" class="coupon_valid">{{ translateWord('Cupom aplicado com sucesso') }}</p>
        <p v-if="!hasCouponAndIsValid" class="coupon_invalid">{{ reasonCouponInvalid }}</p>

        <div class="coupon_actions">
            <div class="coupon_actions_input">
                <div v-if="couponInput != '' && coupon.code != null" class="coupon_valueAdd">
                    <span v-if="!notRemove" @click="removeCoupon" class="coupon_valueAdd_remove">
                        <icon class="coupon_valueAdd_remove_icon" name="delete" />
                    </span>

                    {{ couponInput }}
                </div>

                <at-input
                    :status="hasCouponAndIsValid ? 'success' : hasCoupon && !hasCouponAndIsValid ? 'error' : ''"
                    name="email"
                    v-model="couponInput"
                    :placeholder="translateWord('Codigo De Desconto')"/>
            </div>

            <at-button v-if="!notRemove" id="btnCoupon" type="primary" size="small" :disabled="enabledBtnCoupon" @click="validCoupon">{{ translateWord('Aplicar') }}</at-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: "Coupon",
    props: {
        notRemove: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            couponInput: ''
        }
    },
    created(){
        this.couponInput = this.hasCoupon ? this.coupon.code : '';
    },
    computed: {
        ...mapState([
            'coupon',
        ]),
        ...mapGetters([
            'hasCoupon',
            'hasCouponAndIsValid',
            'reasonCouponInvalid',
            'translateWord'
        ]),
        enabledBtnCoupon(){
            return this.couponInput == '' || this.hasCoupon ? true : false;
        },
    },
    methods: {
        ...mapMutations([
            'clearCoupon'
        ]),
        ...mapActions([
            'validateCoupon'
        ]),
        removeCoupon(){
            this.couponInput = '';
            this.clearCoupon();
        },
        async validCoupon(){
            await this.validateCoupon({
                couponUser: this.couponInput,
                isLoadingCheckout: false
            });
        },
    }
}
</script>

<style lang="scss">
    .coupon_invalid{
        color: var(--red);
        font-weight: bold;
        font-size: 0.8em;
        margin-bottom: -23px;
    }
    .coupon_valid{
        @extend .coupon_invalid;
        color: #13CE66;
    }

    .coupon_actions{
        display: flex;
        align-items: center;
        margin-top: 30px;

        & .at-btn{
            margin: 0px;
            padding: 6px 12px !important;
        }
    }

    .coupon_actions_input{
        position: relative;
        width: 70%;

        & .at-input{
            margin-bottom: 0px;
            margin-right: 10px;

            & .at-input__original{
                font-size: 0.9em;
                padding: 10px !important;
            }
        }
    }

    .coupon_valueAdd{
        position: absolute;
        top: 8px;
        left: 10px;
        background: var(--white);
        border: 1px solid #B6B6B6;
        padding: 2px 8px;
        border-radius: 5px;
        z-index: 2;
    }

    .coupon_valueAdd_remove{
        position: absolute;
        top: -5px;
        right: -8px;
        font-size: 0.5em;
        color: var(--white);
        background: var(--gray-500);
        padding: 2px 4px;
        border-radius: 50%;
        cursor: pointer;
    }
</style>